<template>
  <div>
      <video class="video-player" preload="auto" autoplay="autopĺay" muted loop playsinline id="video-bg-main">
        <source src="/img/content2/arvore.mp4" type="video/mp4">
      </video>
      <div class="content">
        <h3 class="py-2">Environmental Corporation</h3   >
      </div>
  </div>
</template>
<script>
  export default {
    name: 'CarouselPage',
  }
</script>
<style scoped>
  /* Style the video: 100% width and height to cover the entire window */
#video-bg-main {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

/* Add some content at the bottom of the video/page */
.content {
  position: fixed;
  bottom: 0;
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}
</style>