<template>
    <div style="height: 100vh !important;">
        <Header color="trans" />
        <MainCarousel />
    </div>
</template>
<script>
    import Header from '../components/Header';
    import MainCarousel from '../components/MainCarousel';

    export default {
        name: "Main",
        components: {
            Header,
            MainCarousel
        },
        mounted(){
            console.log(this.$route.path);
        }
    }
</script>